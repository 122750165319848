<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "tiGuan",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到踢馆页",
                            "#按图1说明参数:",
                            "1.必填项-技能:打BOSS的输出技能,建议用最高输出套路",
                            "2.必填项-角色:根据在队伍中的角色选择队长或者队员",
                            "3.必填项-角色挂机:勾选后,角色只会跟着加入踢馆,移动到下一个势力,拾取奖励,但是不会靠近boss输出",
                            "4.必填项-势力:勾选要挫败的势力,从头到尾刷,刷完停止",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#注意界面说明(图1图2图3)",
                            "#把马哨放到快捷栏上,优先使用马哨",
                            "#把打坐技能放到快捷栏上,每次打完BOSS自动打坐",
                            "#游戏提前组好队伍,根据队伍中角色设置参数,如果没有'立即前往',需要手动移动到第一个势力门口",
                            '以上设置好,开始任务',
                        ],
                        img:[
                            "2.png",
                            "3.png",
                            "4.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            setTimeout(res =>{
                this.$refs.row.initData(this.list);
            },100)
        }
    }
</script>

<style scoped>

</style>
